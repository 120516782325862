var API = '/api/1.1/0/';

(function () {
  $('.confirm-action').click(function (e) {
    e.preventDefault()
    bootbox.confirm('<span style="font-size: 20px;">Are you sure that you want to do this?</span>', function (response) {
      if (response) { window.location.href = e.target.href }
    })
    return false
  })
  $('.has-datetime').datetimepicker({
    format: 'YYYY-MM-DD HH:mm:ss'
  })
  $('.has-date').datetimepicker({
    format: 'YYYY-MM-DD'
  })
  $('.has-date-finance').datetimepicker({
    format: 'DD-MM-YYYY'
  })
  $('.has-date-evaluation').datetimepicker({
    format: 'DD-MM-YYYY'
  })
  $('.has-time').datetimepicker({
    format: 'HH:mm:ss'
  })

  $('.form-report .btn-shortcut').click(function () {
    $('.form-report').find('input[name=date_start]').val($(this).data('date-start'))
    $('.form-report').find('input[name=date_end]').val($(this).data('date-end'))
    $('.form-report').submit()
  })
}).call(this)

var dropzone = angular.module('dropzone', []).directive('dropzone', function () {
  return function (scope, element, attrs) {
    var config, dropzone

    config = scope[attrs.dropzone]

    // create a Dropzone for the element with the given options
    dropzone = new Dropzone(element[0], config.options)

    // // bind the given event handlers
    angular.forEach(config.eventHandlers, function (handler, event) {
      dropzone.on(event, handler)
    })
  }
})

dropzone
  .config(function ($httpProvider) {
    /**
     * adds CSRF token to header
     */
    $httpProvider.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content')

    /**
     * add xml http request to be detected as ajax request by laravel
     */
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  })

var app = angular.module('myhijau', ['dropzone', 'blockUI']).directive('fileModel', ['$parse', function ($parse) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var model = $parse(attrs.fileModel)
      var modelSetter = model.assign

      element.bind('change', function () {
        scope.$apply(function () {
          modelSetter(scope, element[0].files[0])
        })
      })
    }
  }
}])

app
  .config(function ($httpProvider) {
    /**
     * adds CSRF token to header
     */
    $httpProvider.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content')

    /**
     * add xml http request to be detected as ajax request by laravel
     */
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  })
  .factory('Company', function ($scope, $http) {
    var checkRegistrationNumber = function (reg_no) {
      var registrationNumberData = { reg_no: reg_no }
      var registrationNumberClear = false
      if (registrationNumberData != '') {
        $http.post('/companies/searchRegistrationNumber', registrationNumberData)
          .success(function (messages) {
            if (messages == 'ok') {
              registrationNumberClear = true
            } else {
              registrationNumberClear = false
            }
          })
          .error(function (err) {
            console.error(err)
          })
      }
    }
    var test = function () {
      console.log('test')
    }
    return {
      checkRegNo: function (reg_no) {
        checkRegistrationNumber(reg_no)
      },
      test: function () {
        test()
      }
    }
  })
  .filter('tel', function () {
    return function (tel) {
      var value = tel.toString().trim()
      var country, city, number

      first = value.slice(0, 2)
      second = value.slice(2, 6)
      third = value.slice(6)

      return first + ' ' + second + ' ' + third
    }
  })
  .filter('datetimeToDate', function () {
    return function (datetime) {
      var value = datetime.toString().trim()
      return value.slice(0, 10)
    }
  })
