app

  .factory('Anchor', ["$localtion", "$anchorScroll", function ($localtion, $anchorScroll) {
    var scroll = function (anchor_name) {
      $location.hash(anchor_name)
      $anchorScroll()
    }
  }])

  .factory('MainCerts', ["$http", "$rootScope", function ($http, $rootScope) {
    var fetchMainCerts = function (application_type) {
      var old_data = old_data || ''
      var new_data = ''
      $http.get('/api/main-certs/lists?application_type=' + (application_type == 'P' ? 1 : 2))
        .success(function (maincertsNew) {
          $rootScope.main_certs_list = maincertsNew
        })
        .error(function (err) {
          console.error(err)
        })
    }
    return {
      fetch: function (application_type) {
        fetchMainCerts(application_type)
      }
    }
  }])

  .factory('Applications', ["$http", "$rootScope", function ($http, $rootScope) {
    var updateDraftTab = function (application, stage) {
      application.draft_tab = stage
      $http.post('/api/applications/' + application.id + '/tab', application)
        .error(function (err) {
          console.error(err)
        })
    }
    var fetchApplication = function (role, company_id, blockUI) {
      var old_data = old_data || ''
      var new_data = ''
      company_id = company_id == 0 ? '' : company_id

      if (true) {
      }

      $http.get('/api/applications/lists?company_id=' + company_id + '&role=' + role)
        .success(function (applications) {
          $rootScope.table.table1 = applications.table1
          $rootScope.table.table2 = applications.table2
          $rootScope.table.table3 = applications.table3
          $rootScope.table.table4 = applications.table4
          $rootScope.table.option.summary = applications.option.summary
          $rootScope.table.option.box = applications.option.box
          $rootScope.table.option.box1 = applications.box1
          $rootScope.table.option.box2 = applications.box2
          $rootScope.table.option.box3 = applications.box3
          $rootScope.table.option.box4 = applications.box4
          $rootScope.table.option.table1 = applications.option.table1
          $rootScope.table.option.table2 = applications.option.table2
          $rootScope.table.option.table3 = applications.option.table3
          $rootScope.table.option.table4 = applications.option.table4
        })
        .error(function (err) {
          console.error(err)
        })
    }
    var fetchSummary = function (api_version, api_subversion, tables, periods, renewal, statuses) {
      var new_data = ''

      $http.get('/api/' + api_version + '/' + api_subversion + '/directory/applicationSummary?tables=' + tables + '&periods=' + periods + '&renewal=' + renewal + '&statuses=' + statuses)
        .then(function (success) {
          $rootScope.table.appSummary.monthly.companies.certified = success.data.monthly.companies.certified
          $rootScope.table.appSummary.monthly.products.certified = success.data.monthly.product_items.certified
          $rootScope.table.appSummary.monthly.services.certified = success.data.monthly.service_items.certified
          $rootScope.table.appSummary.monthly.items.certified = success.data.monthly.product_items.certified + success.data.monthly.service_items.certified
          $rootScope.table.appSummary.monthly.finance.certified = success.data.monthly.finance.certified
          $rootScope.table.appSummary.monthly.applications.certified = success.data.monthly.products.certified + success.data.monthly.services.certified

          $rootScope.table.appSummary.yearly.companies.certified = success.data.yearly.companies.certified
          $rootScope.table.appSummary.yearly.products.certified = success.data.yearly.product_items.certified
          $rootScope.table.appSummary.yearly.services.certified = success.data.yearly.service_items.certified
          $rootScope.table.appSummary.yearly.items.certified = success.data.yearly.product_items.certified + success.data.yearly.service_items.certified
          $rootScope.table.appSummary.yearly.finance.certified = success.data.yearly.finance.certified
          $rootScope.table.appSummary.yearly.applications.certified = success.data.yearly.products.certified + success.data.yearly.services.certified
        })
    }

    return {
      draftTab: function (application, stage) {
        updateDraftTab(application, stage)
      },
      fetch: function (role, company_id) {
        fetchApplication(role, company_id)
      },
      summary: function (api_version, api_subversion, tables, periods, renewal, statuses) {
        fetchSummary(api_version, api_subversion, tables, periods, renewal, statuses)
      }
    }
  }])

  .factory('Directories', ["$http", "$rootScope", function ($http, $rootScope) {
    return {
      fetch: function (setting, searchData) {
        console.log('before check', setting.application_type, searchData.application_type, setting.category, searchData.category)

        if (setting.application_type == searchData.application_type && setting.category != searchData.category) {
          $rootScope.category[setting.application_type] = [] // reset all data if category is not same
        }

        console.log('after check', setting.application_type, searchData.application_type, setting.category, searchData.category)

        searchData.skip = setting.length || 0
        $http.post('/api/1.1/0/directory/fetch-items-by-category', searchData)
          .then(
            function (directoriesNew) {
              if (directoriesNew.data != '') {
                directoriesNew.data.forEach(function (directoryNew) {
                  $rootScope.category[setting.application_type].push(directoryNew)
                })
              } else {
                $rootScope.loadmore = false
              }
              setting.application_type = searchData.application_type
              setting.category = searchData.category

              // console.log('after success',
              //     setting.application_type,
              //     searchData.application_type,
              //     setting.category,
              //     searchData.category,
              //     $rootScope.category[setting.application_type].length);
            },
            function (err) {
              console.error(err)
            },
            function () {
              return $rootScope.category[setting.application_type].length
            }
          )
      }
    }
  }])

  .factory('Products', ["$http", function ($http) {
    var fetchMyHijau = function (search) {
      $http.get('/api/products/search?keyword=' + search)
        .success(function (product) {
          $rootScope.product = product
        })
        .error(function (err) {
          console.error(err)
        })
    }
  }])

  .factory('CategoryService', ["$http", function ($http) {
    return {
      fetch: function () {
        return $http.get(API + 'directory/fetch-categories')
      }
    }
  }])

  .factory('SubcategoryService', ["$http", function ($http) {
    return {
      fetch: function (category_id) {
        return $http.post(API + 'directory/fetch-subcategories', {'category': category_id})
      }
    }
  }])

app
  .controller('CompaniesRegistrationController', ["$scope", "$http", function ($scope, $http) {
    $scope.check = {
      tnc: {
        show: false,
        clear: false
      },
      reg_no_clear: '',
      email: {
        clear: ''
      },
      password: {
        clear: '',
        match: '',
        lgt: '',
        uc: '',
        lc: '',
        num: '',
        sp: ''
      }
    }

    $scope.data = {
      company: {
        reg_no: ''
      },
      selfCheck: {
        type: '',
        fail: 0,
        validity: '',
        photo: '',
        creteria: '',
        cert: '',
        success: '',
        // success: 1,
        cert_list: '',
        qualification: '',
        documentation: '',
        value: '0'
      },
      setting: {
        password: {
          lgt: metadata.settings.password_rule_length,
          uc: metadata.settings.password_rule_uppercase,
          lc: metadata.settings.password_rule_lowercase,
          num: metadata.settings.password_rule_numeric,
          sp: metadata.settings.password_rule_special
        }
      },
      user: {
        password: '',
        password_confirmation: ''
      },
      fail: {
        id: '',
        name: '',
        email: '',
        phone: ''
      }
    }

    $scope.checkRegistrationNumber = function () {
      var registrationNumber = $scope.data.company.reg_no

      if (registrationNumber != '') {
        $http.post('/companies/searchRegistrationNumber', { reg_no: registrationNumber })
          .success(function (messages) {
            $scope.check.reg_no_clear = messages === 'ok'
          })
          .error(function (err) {
            console.error(err)
            $scope.check.reg_no_clear = false
          })
      } else {
        $scope.check.reg_no_clear = ''
      }
    }

    $scope.checkEmail = function () {
      var email = $scope.data.user.email
      if (email != '') {
        $http.post('/users/searchEmail', { email: email })
          .success(function (messages) {
            $scope.check.email.clear = (messages === 'ok')
          })
          .error(function (err) {
            console.error(err)
            $scope.check.email.clear = false
          })
      } else {
        $scope.check.email.clear = ''
      }
    }

    $scope.onSubmitMailingList = function () {
      $http.post('/mailing-lists', $scope.data.fail)
        .success(function (status) {
          $scope.data.fail = status
          setTimeout(function () {
            window.location = 'http://myhijau.my'
          }, 5000)
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.passwordMatchChecker = function () {
      if ($scope.data.user.password_confirmation != '') {
        $scope.check.password.match = ($scope.data.user.password === $scope.data.user.password_confirmation)
      } else {
        $scope.check.password.match = ''
      }
    }

    $scope.passwordStrengthChecker = function () {
      $scope.check.password.clear = '' // reset every time recheck;
      if ($scope.check.password.clear !== false) {

      } else {

      }

      if ($scope.check.password.clear !== false) {
        if ($scope.data.user.password.length >= $scope.data.setting.password.lgt) {
          $scope.check.password.lgt = true
        } else {
          $scope.check.password.lgt = false
          $scope.check.password.clear = false
        }
      } else {
        $scope.check.password.lgt = true
      }

      if ($scope.data.setting.password.uc && $scope.check.password.clear !== false) {
        var uc = new RegExp('[A-Z]')

        if (uc.test($scope.data.user.password)) {
          $scope.check.password.uc = true
        } else {
          $scope.check.password.uc = false
          $scope.check.password.clear = false
        }
      } else {
        $scope.check.password.uc = true
      }

      if ($scope.data.setting.password.lc && $scope.check.password.clear !== false) {
        var lc = new RegExp('[a-z]')

        if (lc.test($scope.data.user.password)) {
          $scope.check.password.lc = true
        } else {
          $scope.check.password.lc = false
          $scope.check.password.clear = false
        }
      } else {
        $scope.check.password.lc = true
      }

      if ($scope.data.setting.password.num && $scope.check.password.clear !== false) {
        var num = new RegExp('[0-9]')

        if (num.test($scope.data.user.password)) {
          $scope.check.password.num = true
        } else {
          $scope.check.password.num = false
          $scope.check.password.clear = false
        }
      } else {
        $scope.check.password.num = true
      }

      if ($scope.data.setting.password.sp && $scope.check.password.clear !== false) {
        var sp = new RegExp('[^a-zA-Z\d]')

        if (sp.test($scope.data.user.password)) {
          $scope.check.password.sp = true
        } else {
          $scope.check.password.sp = false
          $scope.check.password.clear = false
        }
      } else {
        $scope.check.password.sp = true
      }
    }
  }])

app
  .directive('checkImageFileSize', function () {
    /**
         * check image file size untuk 2 mb
         */
    return {
      link: function (scope, elem, attr, ctrl) {
        function bindEvent (element, type, handler) {
          if (element.addEventListener) {
            element.addEventListener(type, handler, false)
          } else {
            element.attachEvent('on' + type, handler)
          }
        }

        bindEvent(elem[0], 'change', function () {
          var fileSize = this.files[0].size / 2048000
          if (fileSize > 2) {
            alert('The file size exceeds the limit allowed. Please select other image. \nMaximum file size is 2MB.')
            angular.element(elem[0]).val(null)
          }
        })
      }
    }
  })
  .directive('checkFileSize', function () {
    /**
         * check image file size untuk 10 mb
         */
    return {
      link: function (scope, elem, attr, ctrl) {
        function bindEvent (element, type, handler) {
          if (element.addEventListener) {
            element.addEventListener(type, handler, false)
          } else {
            element.attachEvent('on' + type, handler)
          }
        }

        bindEvent(elem[0], 'change', function () {
          var fileSize = this.files[0].size / 1024000
          if (fileSize > 10) {
            alert('The file size exceeds the limit allowed. Please select other image. \nMaximum file size is 10MB.')
            angular.element(elem[0]).val(null)
          }
        })
      }
    }
  })
  .controller('applicationProductCreate', ["$rootScope", "$scope", "$http", "$location", "$document", "$window", "$filter", "$anchorScroll", "Applications", "MainCerts", "blockUI", function ($rootScope, $scope, $http, $location, $document, $window, $filter, $anchorScroll, Applications, MainCerts, blockUI) {
    $document.ready(function () {
      var locationPath = $location.path().substr(1)
      if (locationPath !== '') {
        $scope.check.stage = locationPath
        $scope.data.applications = metadata.applications || {}
        $scope.data.products = metadata.products || []
        $scope.data.evaluations = metadata.evaluations || []
        $scope.evaluation_length = Object.keys($scope.data.evaluations).length || 0
        $scope.item_length = Object.keys($scope.data.evaluations.items).length || 0
        $scope.data.main_certs = $scope.data.applications.main_cert || {
          id: '',
          company_id: 0,
          application_type: $scope.application_type,
          expiry_date: '0000-00-00',
          is_active: 1
        }

        if (metadata.txns.hasOwnProperty('id') && metadata.txns.id !== null) {
          // edit some data to payments
          metadata.txns.payment_method_id = metadata.txns.payment_method_id !== null ? metadata.txns.payment_method_id.toString() : null
          metadata.txns.bank_id = metadata.txns.bank_id !== null ? metadata.txns.bank_id.toString() : null
          metadata.txns.received_amount = parseInt(metadata.txns.received_amount)
          var d = new Date(metadata.txns.payment_date)
          metadata.txns.payment_date = d.getDay() + '-' + d.getMonth() + '-' + d.getFullYear()
        }

        if ($scope.evaluation_length > 0) {
          angular.forEach($scope.data.services, function (value, key) {
            angular.forEach($scope.data.evaluations.items[value.id], function (evaluation) {
              $scope.data.evaluations.items[value.id].is_comply = (typeof $scope.data.evaluations.items[value.id].is_comply === 'undefined' ? true : $scope.data.evaluations.items[value.id].is_comply)
              if ($scope.data.evaluations.items[value.id].is_comply === true) {
                if (evaluation.is_comply === false) {
                  $scope.data.evaluations.items[value.id].is_comply = false
                }
              }
            })
          })
        }

        if ($scope.data.applications.is_online_declared == 1) {
          $scope.data.is_online_declared_raw = true
          $scope.check.declarations.clear = true
          $scope.check.declarations.hideBtn = true
        }

        $scope.data.txns = metadata.txns || {
          id: '',
          application_id: '',
          application_type: $scope.application_type
        }
        $scope.data.txn = metadata.txns || {
          id: '',
          application_id: '',
          application_type: $scope.application_type
        }
      } else {
        $scope.check.stage = 'certification'
      }
    })

    $scope.dropzoneConfig = {
      options: { // passed into the Dropzone constructor
        url: '/api/main-certs/upload',
        paramName: 'certs',
        params: {
          _token: $('meta[name="csrf-token"]').attr('content')
        },
        uploadMultiple: false,
        parallelUploads: 100,
        maxFilesize: 10,
        addRemoveLinks: true,
        dictRemoveFile: 'Remove',
        dictFileTooBig: 'Image is bigger than 10MB'
      },
      eventHandlers: {
        sending: function (file, formdata, xhr) {
          // console.log('sending', file)
        },
        success: function (file, response) {
          // var obj = {};
          // obj[count] = response.id;
          // angular.extend($scope.data.main_certs.certs, obj);
          // count++;
          // $scope.data.main_certs.certs[$scope.data.main_certs.certs.length] = response.id
          $(file.previewElement).append('<input type="hidden" class="media-file-id" name="certs[]" value="' + response.id + '"/>')
        },
        error: function (err) {
          console.error(err)
        }
      }
    }

    $rootScope.main_certs_list = []
    $rootScope.products = []
    $scope.application_type = 1
    // $scope.loa = '';

    $scope.data = {
      applications: {
        id: '',
        company_id: 0,
        status_id: '',
        main_cert_id: '',
        type: $scope.application_type,
        is_online_declared: 0
      },
      main_certs: {
        id: '',
        company_id: 0,
        application_type: $scope.application_type,
        expiry_date: '0000-00-00',
        is_active: 1
      },
      ps: [],
      products: [],
      product: {
        application_id: '',
        id: ''
      },
      txns: {
        id: '',
        application_id: '',
        application_type: $scope.application_type
      },
      txn: {
        id: '',
        application_id: '',
        application_type: $scope.application_type
      }
    }

    $scope.check = {
      btn_disabled: false,
      stage: 'onSubmitCreateCertification', // certification
      sucess: false,
      step: 1,
      main_certs: {
        clear: '',
        new: false,
        repair: false,
        status: ''
      },
      products: {
        clear: '',
        new: false,
        review: {
          status: false,
          id: 0,
          index: 0
        },
        edit: {
          status: false,
          id: 0,
          index: 0
        }
      },
      payments: {
        clear: '',
        update: false
      },
      declarations: {
        clear: '',
        hideBtn: ''
      }
    }

    MainCerts.fetch('P')

    $scope.resetProductData = function () {
      $scope.data.product = {
        application_id: '',
        id: ''
      }
    }

    $scope.downloadInvoice = function () {
      console.log('download')
      $window.open('/applications/' + $scope.data.applications.id + '/invoice', '_blank')
    }

    $scope.onSubmitCreateCertification = function () {
      // if main cert id is null
      if ($scope.data.main_certs.id == '') {
        $scope.data.main_certs_submit = {
          name: $scope.data.main_certs.name,
          application_type: $scope.data.main_certs.application_type,
          company_id: $scope.data.main_certs.company_id,
          is_active: $scope.data.main_certs.is_active
        }
        $scope.check.btn_disabled = true
        $scope.mediaDataMainCert = ($scope.mediaDataMainCert || {})
        if (!$scope.ac) {
          $scope.mediaDataMainCert.ac = $scope.ac
        }

        $http.post('/api/main-certs', {data: $scope.data.main_certs_submit, media: $scope.mediaDataMainCert}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })

            $('.media-file-id').each(function (index, el) {
              formData.append('certs[]', el.value)
            })

            formData.append('ac', $scope.ac)
            return formData
          }
        })
          .success(function (maincerts) {
            $rootScope.main_certs_list.push(maincerts)
            $scope.data.main_certs.id = maincerts.id
            $scope.data.applications.main_cert_id = maincerts.id
            $scope.data.applications.company_id = maincerts.company_id
            $scope.check.main_certs.new = false
          })
          .error(function (err) {
            console.error(err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      }
    }

    $scope.onSubmitRepairCertification = function () {
      // if main cert id is null
      if ($scope.data.main_certs.id != '') {
        $scope.data.main_certs_submit = {
          name: $scope.data.main_certs.name,
          application_type: $scope.data.main_certs.application_type,
          company_id: $scope.data.main_certs.company_id,
          is_active: $scope.data.main_certs.is_active
        }
        $scope.check.btn_disabled = true
        $scope.mediaDataMainCert = ($scope.mediaDataMainCert || {})
        if (!$scope.ac) {
          $scope.mediaDataMainCert.ac = $scope.ac
        }

        $http.post('/api/main-certs/' + $scope.data.main_certs.id + '/repair', {
          data: $scope.data.main_certs_submit,
          media: $scope.mediaDataMainCert
        }, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })

            $('.media-file-id').each(function (index, el) {
              formData.append('certs[]', el.value)
            })

            formData.append('ac', $scope.ac)
            return formData
          }
        })
          .success(function (maincerts) {
            $rootScope.main_certs_list.push(maincerts)
            $scope.data.main_certs.id = maincerts.id
            $scope.data.applications.main_cert_id = maincerts.id
            $scope.data.applications.company_id = maincerts.company_id
            $scope.check.main_certs.repair = false
          })
          .error(function (err) {
            console.error(err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      } else {
        console.error('error. main cert missing')
        // $scope.onSubmitCreateCertification();
      }
    }

    $scope.onSubmitCreateApplication = function () {
      var url = '/api/applications'
      if ($scope.data.applications.id !== '') {
        url = url + '/' + $scope.data.applications.id
      }

      if ($scope.data.main_certs.id !== '') {
        $scope.data.applications.main_cert_id = $scope.data.main_certs.id
      }

      if ($scope.loa) {
        $scope.check.btn_disabled = true
        $scope.data.applications.main_cert_id = ($scope.data.applications.main_cert_id === '') ? $scope.data.main_certs.id : $scope.data.applications.main_cert_id
        $scope.mediaData = ($scope.mediaData || {})

        $http.post(url, {data: $scope.data.applications, media: $scope.mediaData}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })
            angular.forEach(data.media, function (value, key) {
              formData.append('media[' + key + ']', value)
            })
            formData.append('loa', $scope.loa)
            return formData
          }
        })
          .success(function (applications) {
            // console.log('success')
            $scope.data.applications.id = applications.id
            $scope.data.applications.loa_url = applications.loa_url
            $scope.data.applications.company_id = ($scope.data.applications.company_id !== '') ? $scope.data.applications.company_id : applications.company_id
            $scope.data.applications.name = applications.name
            Applications.draftTab($scope.data.applications, 'products')
            $scope.check.stage = 'products'
          })
          .error(function (err) {
            console.error(err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      } else {
        console.log('tak masuk')

        $http.post(url, $scope.data.applications, {})
          .success(function (applications) {
            // console.log('success')
            $scope.data.applications.id = applications.id
            $scope.data.applications.loa_url = applications.loa_url
            $scope.data.applications.company_id = ($scope.data.applications.company_id !== '') ? $scope.data.applications.company_id : applications.company_id
            $scope.data.applications.name = applications.name
            Applications.draftTab($scope.data.applications, 'products')
            $scope.check.stage = 'products'
          })
          .error(function (err) {
            console.error(err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      }
    }

    $scope.onSubmitCreateProduct = function () {
      $scope.mediaData = ($scope.mediaData || {})
      if (!$scope.photo1) {
        $scope.mediaData.photo1 = $scope.photo1
      }
      if (!$scope.photo2) {
        $scope.mediaData.photo2 = $scope.photo2
      }
      if (!$scope.photo3) {
        $scope.mediaData.photo3 = $scope.photo3
      }
      if (!$scope.brochure) {
        $scope.mediaData.brochure = $scope.brochure
      }

      if ($scope.data.product.id == '' && $scope.check.products.new) {
        var old_length = $scope.data.products.length
        // console.log('old_length', old_length)
        $scope.data.product.application_id = ($scope.data.product.application_id == '' || $scope.check.products.new) ? $scope.data.applications.id : $scope.data.product.application_id
        $http.post('/api/products', {data: $scope.data.product, media: $scope.mediaData}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })
            formData.append('photo1', $scope.photo1)
            formData.append('photo2', $scope.photo2)
            formData.append('photo3', $scope.photo3)
            formData.append('brochure', $scope.brochure)
            return formData
          }
        })
          .success(function (productNew) {
            $scope.data.products.push(productNew)
            // reset saved data
            $scope.data.product = {
              application_id: '',
              id: ''
            }
            angular.forEach(
              angular.element("input[type='file']"),
              function (inputElem) {
                angular.element(inputElem).val(null)
              })
            $scope.check.products.review.id = productNew.id
            $scope.check.products.review.index = old_length
            $scope.check.products.review.status = true
          })
          .error(function (err) {
            console.error(err)
          })
      } else {
        $scope.onSubmitUpdateProduct()
      }
    }

    $scope.getEditProduct = function (id, index) {
      $scope.check.products.edit.status = true
      $scope.check.products.edit.id = id
      $scope.check.products.edit.index = index

      $scope.check.products.review.status = false

      angular.forEach($scope.data.products[index], function (value, key) {
        $scope.data.product[key] = value
      })

      $scope.data.product.is_sales_online = parseInt($scope.data.product.is_sales_online)
      $scope.data.product.is_sales_retailer = parseInt($scope.data.product.is_sales_retailer)
      $scope.data.product.is_sales_direct = parseInt($scope.data.product.is_sales_direct)
    }

    $scope.getRemoveProduct = function (id, index) {
      $http.post('/api/products/' + id + '/delete', $scope.data.product)
        .success(function (productNew) {
          console.log('delete success')
        })
        .error(function (err) {
          console.error(err)
        })
        .finally(function () {
          console.log('delete splice')
          $scope.data.products.splice(index, 1)
        })
    }

    $scope.onSubmitUpdateProduct = function (e) {
      // console.log('masuk submit update product')
      $http.post('/api/products/' + $scope.data.product.id, {
        data: $scope.data.product,
        media: $scope.mediaData
      }, {
        headers: {'Content-Type': undefined},
        transformRequest: function (data) {
          var dataNew = {
            id: data.data.id,
            application_id: data.data.application_id,
            name: data.data.name,
            brand: data.data.brand,
            model: data.data.model,
            // pic_designation: data.data.pic_designation,
            // pic_title_id: data.data.pic_title_id,
            // pic_name: data.data.pic_name,
            // pic_phone: data.data.pic_phone,
            // pic_email: data.data.pic_email,
            is_sales_online: data.data.is_sales_online ? 1 : 0,
            sales_online_url: data.data.is_sales_online == 1 ? data.data.sales_online_url : null,
            is_sales_retailer: data.data.is_sales_retailer ? 1 : 0,
            sales_retailer_name: data.data.is_sales_retailer == 1 ? data.data.sales_retailer_name : null,
            sales_retailer_loc: data.data.is_sales_retailer == 1 ? data.data.sales_retailer_loc : null,
            is_sales_direct: data.data.is_sales_direct ? 1 : 0,
            sales_direct_phone: data.data.is_sales_direct == 1 ? data.data.sales_direct_phone : null,
            unpublished_reason: data.data.unpublished_reason != 'null' ? data.data.unpublished_reason : null
          }
          var formData = new FormData()
          angular.forEach(dataNew, function (value, key) {
            formData.append(key, value)
          })
          if ($scope.photo1) {
            formData.append('photo1', $scope.photo1)
          }
          if ($scope.photo2) {
            formData.append('photo2', $scope.photo2)
          }
          if ($scope.photo3) {
            formData.append('photo3', $scope.photo3)
          }
          if ($scope.brochure) {
            formData.append('brochure', $scope.brochure)
          }
          return formData
        }
      })
        .success(function (productNew) {
          console.log(productNew)
          $scope.data.products[$scope.check.products.edit.index] = productNew
          // reset saved data
          $scope.data.product = {}
          $scope.check.products.review.id = productNew.id
          $scope.check.products.review.status = true
        })
        .error(function (err) {
          console.error(err)
        })
      // .finally(function() {
      //   $scope.data.product = {}
      // })
    }

    $scope.getReviewProduct = function (id, index) {
      $scope.check.products.review.status = true
      $scope.check.products.review.id = id
      $scope.check.products.review.index = index

      $scope.check.products.review.edit = false
    }

    $scope.onReviewedProduct = function () {
      $http.get('/api/products/' + $scope.check.products.review.id + '/reviewed')
        .success(function (status) {
          if (status === 'success') {
            $scope.data.products[$scope.check.products.review.index].is_reviewed = 1
            $scope.check.products.review.status = false
            $scope.check.products.review.id = 0
            $scope.check.products.review.index = 0

            $scope.check.products.new = false
            $scope.check.products.edit.status = false

            $scope.data.product = {} // just make sure its clear.
          }
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.onSubmitCreateTxn = function () {
      if ($scope.data.txns.id == '' || $scope.data.txns.id == undefined) {
        $scope.data.txn.payment_date = document.getElementById('paymentDate').value
        $scope.data.txn.application_id = $scope.data.txn.application_id ? $scope.data.txn.application_id : $scope.data.applications.id
        $scope.data.txn.application_type = $scope.data.txn.application_type ? $scope.data.txn.application_type : $scope.data.applications.type

        $http.post('/api/txns', {data: $scope.data.txn}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })
            formData.append('media', $scope.payment_slip)
            return formData
          }
        })
          .success(function (txnsNew) {
            $scope.data.txns = txnsNew
            $scope.data.ps = $scope.data.products
            Applications.draftTab($scope.data.applications, 'payments')
            console.log('send')
            $scope.check.stage = 'payments'
          })
          .error(function (err) {
            console.error(err)
          })
      } else {
        Applications.draftTab($scope.data.applications, 'payments')
        $scope.check.stage = 'payments'
      }
    }

    $scope.onSubmitUpdateTxn = function () {
      $scope.data.txn.id = $scope.data.txn.id != '' ? $scope.data.txn.id : $scope.data.txns.id

      // overwrite the data when submit to update the data.
      $scope.data.txns.payment_date = document.getElementById('paymentDate').value

      $scope.data.txns.payment_method_id = $scope.data.txns.payment_method_id != null ? $scope.data.txns.payment_method_id : $scope.data.txn.payment_method_id

      $http.post('/api/txns/' + $scope.data.txns.id, {data: $scope.data.txns}, {
        headers: {'Content-Type': undefined},
        transformRequest: function (data) {
          var formData = new FormData()
          angular.forEach(data.data, function (value, key) {
            formData.append(key, value)
          })
          formData.append('payment_slip', $scope.payment_slip)
          return formData
        }
      })
        .success(function (txnsNew) {
          $scope.data.txns = txnsNew
          Applications.draftTab($scope.data.applications, 'declarations')
          $scope.check.stage = 'declarations'
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.onSubmitUpdateApplicationByDeclaration = function () {
      $scope.data.applications.is_online_declared = ($scope.data.applications.is_online_declared_raw == true) ? 1 : 0
      $scope.data.applications.is_submit_application = 1 // token for check application is submitted.
      $http.post('/api/applications/' + $scope.data.applications.id, $scope.data.applications)
        .success(function (application) {
          if (application.status_id == 2 || application.status_id == 4 || application.status_id == 19) {
            $scope.check.stage = 'thankyou'
          }
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.gotoPaymentProof = function () {
      $location.hash('payment-proof')
      $anchorScroll()
    }

    $scope.doUploadSDF = function () {
      console.log('enter')
      $http.post('/api/applications/' + $scope.data.applications.id + '/uploadsdf', {
        data: $scope.data.txn,
        media: $scope.mediaData
      }, {
        headers: {'Content-Type': undefined},
        transformRequest: function (data) {
          var formData = new FormData()
          formData.append('sdf', $scope.sdf)
          return formData
        }
      })
        .success(function (success) {
          console.log(success)
          $scope.data.applications.sdf_url = success
          // $scope.data.ps = $scope.data.products
          // Applications.draftTab($scope.data.applications, 'payments')
          // console.log('send');
          // $scope.check.stage = 'payments'
        })
        .error(function (err) {
          console.error(err)
        })
    }
    $scope.getRemoveMainCertsCertsDocument = function (document_id) {
      if (confirm('Confirm to delete this document?') == true) {
        $http.get('/api/main-certs/' + $scope.data.main_certs.id + '/documents/' + document_id + '/delete-documents')
          .then(function () {
            console.log('success: server delete the file then we can remove the dom')

            var element = document.getElementById('maincert-certs-file-' + document_id)
            console.log(element)
            element.remove()
            return false
          }, function () {
            console.log('error: cannot talk to server to delete the file. quite stubborn dont they?')
            return false
          })
      }
    }
  }])

app
  .directive('checkImageFileSize', function () {
    /**
         * check image file size untuk 2 mb
         */
    return {
      link: function (scope, elem, attr, ctrl) {
        function bindEvent (element, type, handler) {
          if (element.addEventListener) {
            element.addEventListener(type, handler, false)
          } else {
            element.attachEvent('on' + type, handler)
          }
        }

        bindEvent(elem[0], 'change', function () {
          var fileSize = this.files[0].size / 2048000
          if (fileSize > 2) {
            alert('The file size exceeds the limit allowed. Please select other image. \nMaximum file size is 2MB.')
            angular.element(elem[0]).val(null)
          }
        })
      }
    }
  })
  .directive('checkFileSize', function () {
    /**
         * check image file size untuk 10 mb
         */
    return {
      link: function (scope, elem, attr, ctrl) {
        function bindEvent (element, type, handler) {
          if (element.addEventListener) {
            element.addEventListener(type, handler, false)
          } else {
            element.attachEvent('on' + type, handler)
          }
        }

        bindEvent(elem[0], 'change', function () {
          var fileSize = this.files[0].size / 1024000
          if (fileSize > 10) {
            alert('The file size exceeds the limit allowed. Please select other image. \nMaximum file size is 10MB.')
            angular.element(elem[0]).val(null)
          }
        })
      }
    }
  })

app
  .controller('applicationServiceCreate', ["$rootScope", "$scope", "$http", "$document", "$window", "$anchorScroll", "$location", "Applications", "MainCerts", "blockUI", function ($rootScope, $scope, $http, $document, $window, $anchorScroll, $location, Applications, MainCerts, blockUI) {
    $document.ready(function () {
      var locationPath = $location.path().substr(1)
      if (locationPath != '') {
        $scope.check.stage = locationPath
        $scope.data.applications = metadata.applications || {}
        // $scope.data.applications.cert_date = $scope.data.applications.cert_date === '-0001-11-30 00:00:00' ? '0000-00-00' : $scope.data.applications.cert_date;
        // $scope.data.applications.cert_expired_date = $scope.data.applications.cert_expired_date === '-0001-11-30 00:00:00' ? '0000-00-00' : $scope.data.applications.cert_expired_date;
        $scope.data.services = metadata.services || []
        $scope.data.leasings = metadata.leasings || []
        $scope.data.evaluations = metadata.evaluations || []
        $scope.evaluation_length = Object.keys($scope.data.evaluations).length || 0
        $scope.item_length = Object.keys($scope.data.evaluations.items).length || 0
        $scope.data.main_certs = $scope.data.applications.main_cert || {}

        if (metadata.txns.hasOwnProperty('id') && metadata.txns.id !== null) {
          // edit some data to payments
          metadata.txns.payment_method_id = metadata.txns.payment_method_id !== null ? metadata.txns.payment_method_id.toString() : null
          metadata.txns.bank_id = metadata.txns.bank_id !== null ? metadata.txns.bank_id.toString() : null
          metadata.txns.received_amount = parseInt(metadata.txns.received_amount)
          var d = new Date(metadata.txns.payment_date)
          metadata.txns.payment_date = d.getDay() + '-' + d.getMonth() + '-' + d.getFullYear()
        }

        if ($scope.evaluation_length > 0) {
          angular.forEach($scope.data.services, function (value, key) {
            angular.forEach($scope.data.evaluations.items[value.id], function (evaluation) {
              $scope.data.evaluations.items[value.id].is_comply = (typeof $scope.data.evaluations.items[value.id].is_comply === 'undefined' ? true : $scope.data.evaluations.items[value.id].is_comply)
              if ($scope.data.evaluations.items[value.id].is_comply === true) {
                if (evaluation.is_comply === false) {
                  $scope.data.evaluations.items[value.id].is_comply = false
                }
              }
            })
          })
        }

        if ($scope.data.applications.is_online_declared == 1) {
          $scope.data.is_online_declared_raw = true
          $scope.check.declarations.clear = true
          $scope.check.declarations.hideBtn = true
        }

        $scope.data.txns = metadata.txns || {
          id: '',
          application_id: '',
          application_type: $scope.application_type
        }
        $scope.data.txn = metadata.txns || {
          id: '',
          application_id: '',
          application_type: $scope.application_type
        }
      } else {
        $scope.check.stage = 'certification'
      }
    })

    $scope.dropzoneConfig = {
      options: { // passed into the Dropzone constructor
        url: '/api/services/upload/competency-cert',
        paramName: 'competency_cert_files',
        params: {
          _token: $('meta[name="csrf-token"]').attr('content')
        },
        uploadMultiple: false,
        parallelUploads: 100,
        maxFilesize: 10,
        addRemoveLinks: true,
        dictRemoveFile: 'Remove',
        dictFileTooBig: 'Image is bigger than 10MB'
      },
      eventHandlers: {
        sending: function (file, formdata, xhr) {
          // console.log('sending', file)
        },
        success: function (file, response) {
          $(file.previewElement).append('<input type="hidden" class="media-file-id" name="competency_certs[]" value="' + response.id + '"/>')
        },
        error: function (err) {
          console.error(err)
        }
      }
    }

    $rootScope.main_certs_list = []
    $rootScope.services = []
    $rootScope.leasings = []
    $rootScope.product = {}
    $scope.application_type = 2
    $scope.data = {
      applications: {
        type: $scope.application_type,
        main_cert_id: '',
        company_id: 0,
        status_id: 1,
        business_nature: 'Service Provider', // service provider
        id: '',
        is_online_declared: 0
      },
      main_certs_list: [],
      main_certs: {
        id: '',
        application_type: $scope.application_type,
        expiry_date: '0000-00-00',
        is_active: 1,
        company_id: 0
      },
      ps: [],
      services: [],
      service: {
        id: '',
        application_id: '',
        photo1_url: '',
        photo2_url: '',
        photo3_url: '',
        brochure: ''
      },
      leasings: [],
      leasing: {},
      txns: {
        id: '',
        application_id: '',
        application_type: $scope.application_type
      },
      txn: {
        id: '',
        application_id: '',
        application_type: $scope.application_type
      },
      search: ''
    }

    $scope.check = {
      stage: 'certification', // certification
      have_leasing: false,
      sucess: false,
      step: 1,
      push: false,
      main_certs: {
        clear: '',
        new: false,
        repair: false,
        status: ''
      },
      services: {
        clear: '',
        new: false,
        review: {
          status: false,
          id: 0,
          index: 0
        },
        edit: {
          status: false,
          id: 0,
          index: 0
        }
      },
      leasings: {
        clear: '',
        new: false,
        is_pre_add: false,
        getLeasing: '',
        review: {
          status: false,
          id: 0,
          index: 0
        },
        edit: {
          status: false,
          id: 0,
          index: 0
        }
      },
      payments: {
        clear: '',
        update: false
      },
      declarations: {
        clear: '',
        hideBtn: ''
      }
    }

    MainCerts.fetch('S')

    $scope.resetServiceData = function () {
      $scope.data.service = {
        id: '',
        application_id: '',
        photo1_url: '',
        photo2_url: '',
        photo3_url: '',
        brochure: ''
      }
    }

    $scope.downloadInvoice = function () {
      console.log('download')
      $window.open('/applications/' + $scope.data.applications.id + '/invoice', '_blank')
    }

    $scope.onSubmitCreateCertification = function () {
      // if main cert id is null
      if ($scope.data.main_certs.id == '') {
        // if (!$scope.check.push) {
        $scope.data.main_certs_submit = {
          name: $scope.data.main_certs.name,
          application_type: $scope.data.main_certs.application_type,
          company_id: $scope.data.main_certs.company_id,
          is_active: $scope.data.main_certs.is_active
        }
        $scope.check.btn_disabled = true
        $scope.mediaDataMainCert = ($scope.mediaDataMainCert || {})
        if (!$scope.ac) {
          $scope.mediaDataMainCert.ac = $scope.ac
        }

        // $scope.check.push = true;
        $http.post('/api/main-certs', {data: $scope.data.main_certs_submit, media: $scope.mediaDataMainCert}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })

            formData.append('green_policy', $scope.green_policy)
            return formData
          }
        })
          .success(function (maincerts) {
            $rootScope.main_certs_list.push(maincerts)
            $scope.data.main_certs.id = maincerts.id
            $scope.data.applications.main_cert_id = maincerts.id
            $scope.data.applications.company_id = maincerts.company_id
            $scope.check.main_certs.new = false
            angular.forEach(
              angular.element("input[type='file']"),
              function (inputElem) {
                angular.element(inputElem).val(null)
              }
            )
            // $scope.check.push = false;
          })
          .error(function (err) {
            console.error(err)
            // $scope.check.push = false;
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      }
    }

    $scope.onSubmitRepairCertification = function () {
      // if main cert id is null
      if ($scope.data.main_certs.id != '') {
        $scope.data.main_certs_submit = {
          name: $scope.data.main_certs.name,
          application_type: $scope.data.main_certs.application_type,
          company_id: $scope.data.main_certs.company_id,
          is_active: $scope.data.main_certs.is_active
        }
        $scope.check.btn_disabled = true
        $scope.mediaDataMainCert = ($scope.mediaDataMainCert || {})
        if (!$scope.ac) {
          $scope.mediaDataMainCert.ac = $scope.ac
        }

        $http.post('/api/main-certs/' + $scope.data.main_certs.id + '/repair', {
          data: $scope.data.main_certs_submit,
          media: $scope.mediaDataMainCert
        }, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })

            formData.append('green_policy', $scope.green_policy)
            return formData
          }
        })
          .success(function (maincerts) {
            $rootScope.main_certs_list.push(maincerts)
            $scope.data.main_certs.id = maincerts.id
            $scope.data.applications.main_cert_id = maincerts.id
            $scope.data.applications.company_id = maincerts.company_id
            $scope.check.main_certs.repair = false
          })
          .error(function (err) {
            console.error(err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      } else {
        console.error('error. main cert missing')
        // $scope.onSubmitCreateCertification();
      }
    }

    $scope.onSubmitCreateApplication = function () {
      if ($scope.data.applications.id == '') {
        $scope.check.btn_disabled = true
        $scope.data.applications.main_cert_id = ($scope.data.applications.main_cert_id == '') ? $scope.data.main_certs.id : $scope.data.applications.main_cert_id
        $scope.mediaData = ($scope.mediaData || {})

        $http.post('/api/applications', $scope.data.applications)
          .success(function (applications) {
            $scope.data.applications.id = applications.id
            $scope.data.applications.company_id = ($scope.data.applications.company_id != '') ? $scope.data.applications.company_id : applications.company_id
            $scope.data.applications.name = applications.name
            $scope.check.stage = 'services'
          })
          .error(function (err) {
            console.error('On Submit Create New Application fails: ', err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      } else {
        $scope.data.applications.main_cert_id = $scope.data.main_certs.id
        $http.post('/api/applications/' + $scope.data.applications.id, $scope.data.applications, {})
          .success(function (applications) {
            $scope.data.applications.id = applications.id
            $scope.data.applications.company_id = ($scope.data.applications.company_id != '') ? $scope.data.applications.company_id : applications.company_id
            $scope.data.applications.name = applications.name
            Applications.draftTab($scope.data.applications, 'services')
            $scope.check.stage = 'services'
          })
          .error(function (err) {
            console.error('On Submit Update Existing Application fails: ', err)
          })
          .finally(function () {
            $scope.check.btn_disabled = false
          })
      }
    }

    $scope.onSubmitCreateService = function () {
      if ($scope.data.service.id == '' && $scope.check.services.new) {
        if (!$scope.photo1) {
          $scope.mediaData.photo1 = $scope.photo1
        }
        if (!$scope.photo2) {
          $scope.mediaData.photo2 = $scope.photo2
        }
        if (!$scope.photo3) {
          $scope.mediaData.photo3 = $scope.photo3
        }
        if (!$scope.brochure) {
          $scope.mediaData.brochure = $scope.brochure
        }
        if (!$scope.sop) {
          $scope.mediaData.sop = $scope.sop
        }
        var old_length = $scope.data.service.length
        $scope.data.service.application_id = ($scope.data.service.application_id == '' || $scope.check.services.new) ? $scope.data.applications.id : $scope.data.service.application_id
        // console.log('data.service.application_id', $scope.data.service.application_id);
        // console.log('check.services.new', $scope.check.services.new);
        // console.log('data.applications.id', $scope.data.applications.id);
        $http.post('/api/services', {data: $scope.data.service, media: $scope.mediaData}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })

            $('.media-file-id').each(function (index, el) {
              formData.append('competency_certs[]', el.value)
            })

            formData.append('photo1', $scope.photo1)
            formData.append('photo2', $scope.photo2)
            formData.append('photo3', $scope.photo3)
            // formData.append('green_policy', $scope.greenpolicy)
            formData.append('sop', $scope.sop)
            formData.append('brochure', $scope.brochure)

            angular.forEach(
              angular.element("input[type='file']"),
              function (inputElem) {
                angular.element(inputElem).val(null)
              }
            )

            angular.forEach(
              angular.element('div.dz-success'),
              function (dzElem) {
                angular.element(dzElem).remove()
              }
            )

            return formData
          }
        })
          .success(function (serviceNew) {
            console.log(serviceNew)
            $scope.data.services.push(serviceNew)
            // reset saved data
            $scope.check.services.new = false
            $scope.data.service = {
              id: '',
              application_id: '',
              photo1_url: '',
              photo2_url: '',
              photo3_url: '',
              brochure: '',
              competency_cert_file: []
            }
            angular.forEach(
              angular.element("input[type='file']"),
              function (inputElem) {
                angular.element(inputElem).val(null)
              })
            $scope.check.services.review.id = serviceNew.id
            $scope.check.services.review.status = true
          })
          .error(function (err) {
            console.error('fatal error', err)
          })
      } else {
        $scope.onSubmitUpdateService()
      }
    }

    $scope.getEditService = function (id, index) {
      $scope.check.services.edit.status = true
      $scope.check.services.edit.id = id
      $scope.check.services.edit.index = index

      $scope.check.services.new = false
      $scope.check.services.edit.id = 0
      $scope.check.services.review.status = false

      angular.forEach($scope.data.services[index], function (value, key) {
        $scope.data.service[key] = value
      })
    }

    $scope.getRemoveService = function (id, index) {
      $http.post('/api/services/' + id + '/delete', $scope.data.service)
        .success(function (serviceNew) {
          console.log('delete success')
        })
        .error(function (err) {
          console.error(err)
        })
        .finally(function () {
          console.log('delete splice')
          $scope.data.services.splice(index, 1)
        })
    }

    $scope.onSubmitUpdateService = function (e) {
      $http.post('/api/services/' + $scope.data.service.id, {data: $scope.data.service}, {
        headers: {'Content-Type': undefined},
        transformRequest: function (data) {
          var dataNew = {
            id: data.data.id,
            application_id: data.data.application_id,
            name: data.data.name,
            desc: data.data.desc,
            // pic_designation: data.data.pic_designation,
            // pic_title_id: data.data.pic_title_id,
            // pic_name: data.data.pic_name,
            // pic_phone: data.data.pic_phone,
            // pic_email: data.data.pic_email,
            unpublished_reason: data.data.unpublished_reason != 'null' ? data.data.unpublished_reason : null
          }

          var formData = new FormData()
          angular.forEach(dataNew, function (value, key) {
            formData.append(key, value)
          })

          $('.media-file-id').each(function (index, el) {
            formData.append('competency_certs[]', el.value)
          })

          if ($scope.photo1) {
            formData.append('photo1', $scope.photo1)
          }
          if ($scope.photo2) {
            formData.append('photo2', $scope.photo2)
          }
          if ($scope.photo3) {
            formData.append('photo3', $scope.photo3)
          }
          if ($scope.brochure) {
            formData.append('brochure', $scope.brochure)
          }
          // if ($scope.greenpolicy) {
          //   formData.append('green_policy', $scope.greenpolicy)
          // }
          if ($scope.sop) {
            formData.append('sop', $scope.sop)
          }
          return formData
        }
      })
        .success(function (serviceNew) {
          $scope.data.services[$scope.check.services.edit.index] = serviceNew
          // reset saved data
          $scope.data.service = {}
          $scope.check.services.review.id = serviceNew.id
          $scope.check.services.review.status = true
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.getReviewService = function (id, index) {
      $scope.check.services.review.status = true
      $scope.check.services.review.id = id
      $scope.check.services.review.index = index

      $scope.check.services.review.edit = false
    }

    $scope.onReviewedService = function (id, index) {
      $http.get('/api/services/' + id + '/reviewed')
        .success(function (status) {
          if (status == 'success') {
            $scope.data.services[index].is_reviewed = 1

            if ($scope.check.have_leasing) {
              // $scope.getEditService(id, index)
              $scope.check.leasings.is_pre_add = true
            } else {
              $scope.check.services.edit.status = false
              $scope.check.services.edit.id = 0

              $scope.check.services.review.status = false
              $scope.check.services.review.id = 0
              $scope.check.services.review.index = 0
            }

            $scope.check.services.new = false
            // $scope.check.services.review.id = 0
            // $scope.check.services.review.index = 0
          }
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.onSubmitCreateLeasing = function () {
      if ($scope.data.services[$scope.check.services.edit.index].id != '') {
        $scope.data.leasing.service_id = $scope.data.services[$scope.check.services.edit.index].id
        $http.post('/api/leasings', {data: $scope.data.leasing}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })

            formData.append('ac', $scope.ac)
            formData.append('supporting_doc', $scope.supporting_doc)
            formData.append('brochure', $scope.brochure)

            console.log(formData, $scope.ac, $scope.supporting_doc, $scope.brochure)
            return formData
          }
        })
          .success(function (leasingNew) {
            $scope.data.leasings.push(leasingNew)
            // reset saved data
            $scope.data.leasing = {}
            $scope.check.leasings.new = false
            $scope.check.leasings.review.id = leasingNew.id
            $scope.check.leasings.review.status = true
            $scope.check.leasings.getLeasing = false
          })
          .error(function (err) {
            console.error(err)
          })
      } else {
        $scope.onSubmitUpdateService
      }
    }

    $scope.getEditLeasing = function (id, index) {
      console.log(id, index)
      $scope.check.leasings.edit.status = true
      $scope.check.leasings.edit.id = id
      $scope.check.leasings.edit.index = index
      angular.forEach($scope.data.leasings[index], function (value, key) {
        console.log(key, value)
        $scope.data.leasing[key] = value
      })
    }

    $scope.getRemoveLeasing = function (id, index) {
      $http.post('/api/leasings/' + id + '/delete', $scope.data.leasings)
        .success(function (serviceNew) {
          console.log('delete success')
        })
        .error(function (err) {
          console.error(err)
        })
        .finally(function () {
          console.log('delete splice')
          $scope.data.leasings.splice(index, 1)
        })
    }

    $scope.onSubmitUpdateLeasing = function (e) {
      $http.post('/api/services/' + $scope.data.services.edit.id, $scope.data.service)
        .success(function (serviceNew) {
          $scope.data.services[$scope.check.services.edit.index] = $scope.data.service
          // reset saved data
          $scope.data.service = {}
          $scope.check.services.review.id = serviceNew.id
          $scope.check.services.review.status = true
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.getReviewLeasing = function (id, index) {
      $scope.check.leasings.review.status = true
      $scope.check.leasings.review.id = id
      $scope.check.leasings.review.index = index
    }

    $scope.onReviewedLeasing = function () {
      $http.get('/api/leasings/' + $scope.check.services.review.id + '/reviewed')
        .success(function (status) {
          if (status === 'ok') {
            $scope.data.services[$scope.check.leasings.review.index].is_reviewed = 1
            $scope.check.leasings.review.status = false
            $scope.check.leasings.review.id = 0
            $scope.check.leasings.review.index = 0
            $scope.check.leasings.new = false
          }
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.onSubmitCreateTxn = function () {
      if ($scope.data.txns.id == '' || $scope.data.txns.id == undefined) {
        $scope.data.txn.payment_date = document.getElementById('paymentDate').value
        $scope.data.txn.application_id = $scope.data.txn.application_id ? $scope.data.txn.application_id : $scope.data.applications.id
        $scope.data.txn.application_type = $scope.data.txn.application_type ? $scope.data.txn.application_type : $scope.data.applications.type

        $http.post('/api/txns', {data: $scope.data.txn}, {
          headers: {'Content-Type': undefined},
          transformRequest: function (data) {
            var formData = new FormData()
            angular.forEach(data.data, function (value, key) {
              formData.append(key, value)
            })
            formData.append('payment_slip', $scope.payment_slip)
            return formData
          }
        })
          .success(function (txnsNew) {
            $scope.data.txns = txnsNew
            $scope.data.ps = $scope.data.products
            Applications.draftTab($scope.data.applications, 'payments')
            $scope.check.stage = 'payments'
          })
          .error(function (err) {
            console.error(err)
          })
      } else {
        console.log('noting')
        Applications.draftTab($scope.data.applications, 'payments')
        $scope.check.stage = 'payments'
      }
    }

    $scope.onSubmitUpdateTxn = function () {
      $scope.data.txn.id = $scope.data.txn.id != '' ? $scope.data.txn.id : $scope.data.txns.id

      // overwrite the data when submit to update the data.
      $scope.data.txns.payment_date = document.getElementById('paymentDate').value

      $scope.data.txns.payment_method_id = $scope.data.txns.payment_method_id != null ? $scope.data.txns.payment_method_id : $scope.data.txn.payment_method_id

      $http.post('/api/txns/' + $scope.data.txn.id, {data: $scope.data.txn}, {
        headers: {'Content-Type': undefined},
        transformRequest: function (data) {
          var formData = new FormData()
          angular.forEach(data.data, function (value, key) {
            formData.append(key, value)
          })
          formData.append('payment_slip', $scope.payment_slip)
          return formData
        }
      })
        .success(function (txnsNew) {
          $scope.data.txns = txnsNew
          Applications.draftTab($scope.data.applications, 'declarations')
          $scope.check.stage = 'declarations'
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.onSubmitUpdateApplicationByDeclaration = function () {
      $scope.data.applications.is_online_declared = ($scope.data.applications.is_online_declared_raw == true) ? 1 : 0
      $scope.data.applications.is_submit_application = 1 // token for check application is submitted.
      $http.post('/api/applications/' + $scope.data.applications.id, $scope.data.applications)
        .success(function (application) {
          if (application.status_id == 2 || application.status_id == 4 || application.status_id == 19) {
            $scope.check.stage = 'thankyou'
          }
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.getMHProduct = function () {
      $http.post('/api/products/search', {keyword: $scope.data.leasing.myhijau_search})
        .success(function (product) {
          if (product == 'empty') {
            $scope.check.leasings.getLeasing = false
          } else {
            console.log('product', product)
            $scope.check.leasings.getLeasing = true
            $scope.data.leasing.product_id = product.id
            $scope.data.leasing.name = product.name
            $scope.data.leasing.brand = product.brand
            $scope.data.leasing.model = product.model
          }
        })
        .error(function (err) {
          console.error(err)
        })
    }

    $scope.gotoPaymentProof = function () {
      $location.hash('payment-proof')
      $anchorScroll()
    }

    $scope.gotoPaymentProof = function () {
      $location.hash('payment-proof')
      $anchorScroll()
    }

    $scope.getRemoveDocument = function (document_id) {
      if (confirm('Confirm to delete this document?') == true) {
        $http.get('/api/competency-documents/' + document_id + '/delete')
          .then(function () {
            console.log('success: server delete the file then we can remove the dom')

            var element = document.getElementById('doc-com-file-' + document_id)
            console.log(element)
            element.remove()
            return false
          }, function () {
            console.log('error: cannot talk to server to delete the file. quite stubborn dont they?')
            return false
          })
      }
    }

    $('#paymentDate').on('dp.change', function () {
      $scope.data.txn.payment_date = $('#paymentDate').val()
    })

    $scope.doUploadSDF = function () {
      console.log('enter')
      $http.post('/api/applications/' + $scope.data.applications.id + '/uploadsdf', {
        data: $scope.data.txn,
        media: $scope.mediaData
      }, {
        headers: {'Content-Type': undefined},
        transformRequest: function (data) {
          var formData = new FormData()
          formData.append('sdf', $scope.sdf)
          return formData
        }
      })
        .success(function (success) {
          console.log(success)
          $scope.data.applications.sdf_url = success
          // $scope.data.ps = $scope.data.products
          // Applications.draftTab($scope.data.applications, 'payments')
          // console.log('send');
          // $scope.check.stage = 'payments'
        })
        .error(function (err) {
          console.error(err)
        })
    }
  }])

app
// latest directory using new flow
  .controller('DirectoryController', ["$scope", "$rootScope", "$http", "$document", "$location", "Directories", "blockUI", "CategoryService", "SubcategoryService", function ($scope, $rootScope, $http, $document, $location, Directories, blockUI, CategoryService, SubcategoryService) {
    $document.ready(function () {
      // console.log('document.ready view level', $scope.setting.viewLevel);
      $scope.setting.locationPath = $location.path().substr(1)
      // console.log('location path', $location.path(), $location.search());
      $scope.setting.locationSearchParam = $location.search()
      CategoryService.fetch().then(function (data) {
        $scope.reference.categories = data.data
      })

      if ($scope.setting.locationSearchParam.id) {
        // console.log('item.id found:', $scope.setting.locationSearchParam.id);
        $scope.onClickCategory($scope.setting.locationSearchParam.id, $scope.setting.locationSearchParam.type)
      } else {
        // console.log('item.id NOT found, back to listing by category');
        $scope.listingByCategories() // get listing by categories
      }
    })

    // store listing data by categories
    $rootScope.categories = {
      P: [], // products
      S: [] // services
    }

    // store listing data by category
    $rootScope.category = {
      P: [], // products
      S: [] // services
    }

    $rootScope.compare = {
      'status': false,
      'ids': [],
      'counter': 0
    }

    $scope.reference = {
      categories: [],
      subcategories: [],
      qualification_types: [
        {id: 1, name: 'Category 1: Green Label Certification (ISO 14024 Type I ECO-Labels)'},
        {id: 2, name: 'Category 2: Green Label Certification (ISO 14025 Type III ECO Labels)'},
        {
          id: 3,
          name: 'Category 3: Green Label Certification (Other Type I-Like Voluntary Sustainable Scheme; VSS)'
        },
        {id: 4, name: 'Category 4: Performance Standard Compliance'}
      ]
    }

    // store search data from searchbar
    $scope.searchData = ($scope.searchData || {
      keyword: '',
      sector: '',
      category: '',
      subcategory: '',
      user_type: '',
      incentive: '',
      qualification_type: '',
      application_type: 'P'
    })

    // store listing setting
    $scope.setting = ($scope.setting || {
      locationPath: '',
      application_type: 'P', // default P
      length: 0,
      sector: '',
      category: '',
      subcategory: '',
      user_type: '',
      incentive: '',
      qualification_type: '',
      viewLevel: 1 // 1: categories, 2: category, 3: item, 4: qrcode, 5: compare
    })

    $scope.param = {
      categories: [],
      qualification_types: [
        {id: 1, name: 'Category 1: Green Label Certification (ISO 14024 Type I ECO-Labels)'},
        {id: 2, name: 'Category 2: Green Label Certification (ISO 14025 Type III ECO Labels)'},
        {
          id: 3,
          name: 'Category 3: Green Label Certification (Other Type I-Like Voluntary Sustainable Scheme; VSS)'
        },
        {id: 4, name: 'Category 4: Performance Standard Compliance'}
      ]
    }

    $scope.photo_1 = true
    $scope.photo_2 = false
    $scope.photo_3 = false
    $rootScope.itemdetail = false
    $rootScope.loadmore = true

    $scope.toggleCompare = function (value) {
      $scope.compare.status = value
      if (!value) {
        $scope.compareReset()
      }
      if ($scope.setting.viewLevel == 5) {
        $scope.setting.viewLevel = 2
      }
    }

    $scope.compareReset = function () {
      $rootScope.compare.ids = [] // reset all data
      $rootScope.compare.counter = 0
      console.log($rootScope.compare.counter)
    }

    $scope.toCompareList = function (item) {
      var isNew = true
      angular.forEach($scope.compare.ids, function (value, key) {
        if (value.id == item.id) {
          $rootScope.compare.ids.splice(key, 1)
          isNew = false
        }
      })

      if (isNew) {
        $rootScope.compare.ids.push(item)
        $rootScope.compare.counter++
      } else {
        $rootScope.compare.counter--
      }

      console.log($rootScope.compare.ids)
    }

    $scope.doCompare = function () {
      $scope.setting.viewLevel = 5
    }

    $scope.clearSearchData = function () {
      $scope.searchData = {
        keyword: '',
        sector: '',
        category: '',
        subcategory: '',
        user_type: '',
        incentive: '',
        qualification_type: '',
        application_type: $scope.setting.application_type // retrive back to last setting.
      }

      $scope.reference.subcategories = []

      $scope.listingByCategories() // call back to get all base data.
    }

    $scope.loadCategory = function () {
      CategoryService.fetch().then(function (data) {
        $scope.reference.categories = data.data
      })
    }

    $scope.loadSubcategory = function () {
      SubcategoryService.fetch($scope.searchData.category).then(function (data) {
        $scope.reference.subcategories = data.data
      })
    }

    $scope.onClickCategories = function (category_id) {
      blockUI.start()
      $scope.listingByCategory(category_id)
      blockUI.stop()
    }

    $scope.onClickCategory = function (id, type) {
      $http.get(API + 'directory/single/' + type + '/' + id)
        .then(
          function (itemData) {
            $scope.itemData = itemData.data
            if (type == 'C') {
              $scope.setting.viewLevel = 4
              $scope.itemData.application = {'company': $scope.itemData.company}
            } else {
              $scope.setting.viewLevel = 3
            }
          },
          function (err) {
            console.error(err)
          })
    }

    $scope.onClickBackToDirectoryBtn = function () {
      $scope.setting.viewLevel = 1
      $scope.itemData.status_id = 0
      $scope.searchData.category = ''
      $scope.loadMoreCategory() // reload the directory
    }

    $scope.onClickBackToMainCategoryBtn = function () {
      $scope.setting.viewLevel = 2
    }

    $scope.listingByCategories = function () {
      blockUI.start()
      // clear all the selected view
      $rootScope.category = {
        P: [], // products
        S: [] // services
      }
      $http.post(API + 'directory/fetch-items-by-categories', $scope.searchData).then(
        function (results) {
          $scope.setting.application_type = $scope.searchData.application_type
          $scope.categories[$scope.setting.application_type] = results.data
          $scope.setting.viewLevel = 1
        },
        function (err) {
          console.error('listingByCategories error', err)
        }
      ).finally(function () {
        blockUI.stop()
      })
    }

    $scope.listingByCategory = function (category_id) {
      $scope.searchData.category = category_id
      $scope.setting.length = Directories.fetch($scope.setting, $scope.searchData)
      // console.log($scope.setting.length);
      $scope.setting.viewLevel = 2
    }

    $scope.loadMoreCategory = function () {
      if ($scope.searchData.keyword != $scope.setting.keyword || $scope.searchData.sector != $scope.setting.sector || $scope.searchData.category != $scope.setting.category || $scope.searchData.subcategory != $scope.setting.subcategory || $scope.searchData.user_type != $scope.setting.user_type || $scope.searchData.incentive != $scope.setting.incentive || $scope.searchData.qualification_type != $scope.setting.qualification_type) {
        $rootScope.loadmore = true
        $rootScope.category[$scope.setting.application_type] = []
      }

      $scope.setting.keyword = $scope.searchData.keyword
      $scope.setting.sector = $scope.searchData.sector
      $scope.setting.category = $scope.searchData.category
      $scope.setting.subcategory = $scope.searchData.subcategory
      $scope.setting.user_type = $scope.searchData.user_type
      $scope.setting.incentive = $scope.searchData.incentive
      $scope.setting.qualification_type = $scope.searchData.qualification_type

      $scope.searchData.application_type = $scope.setting.application_type

      $scope.setting.length = $rootScope.category[$scope.setting.application_type].length
      Directories.fetch($scope.setting, $scope.searchData)
    }
  }])

app
  .controller('TxnApprovalsController', ["$scope", function ($scope) {
    $scope.is_received = false

    $scope.txnApprovalComfirmation = function () {
      var x
      if (confirm('Confirm?') == true) {
        return true
      } else {
        return false
      }
    }
  }])
  .controller('DashboardsController', ["$scope", "$rootScope", "$http", "$document", "Applications", function ($scope, $rootScope, $http, $document, Applications) {
    $document.ready(function () {
      Applications.fetch($rootScope.table.option.role, $scope.data.company_id)
      Applications.summary('1.1', '0', 'companies,products,services,items,finance', 'monthly,yearly', 'all', 'certified')
    })

    $scope.data = {
      roles: {},
      roleLength: 0,
      company_id: 0
    }

    $rootScope.table = {
      table1: {},
      table2: {},
      table3: {},
      table4: {},
      option: {
        role: '',
        table1: {
          show: true,
          title: 'Application'
        },
        table2: {
          show: true,
          title: 'Certification'
        },
        table3: {
          show: false,
          title: 'Pool'
        },
        table4: {
          show: false,
          title: 'Pool'
        },
        box1: {
          title: 'Loading..',
          number: 0
        },
        box2: {
          title: 'Loading..',
          number: 0
        },
        box3: {
          title: 'Loading..',
          number: 0
        },
        box4: {
          title: 'Loading..',
          number: 0
        }
      },
      appSummary: {
        monthly: {
          companies: {
            certified: 0
          },
          products: {
            certified: 0
          },
          services: {
            certified: 0
          },
          items: {
            certified: 0
          },
          finance: {
            certified: 0
          },
          applications: {
            certified: 0
          }
        },
        yearly: {
          companies: {
            certified: 0
          },
          products: {
            certified: 0
          },
          services: {
            certified: 0
          },
          items: {
            certified: 0
          },
          finance: {
            certified: 0
          },
          applications: {
            certified: 0
          }
        }
      }
    }

    $scope.data.roles = metadata.roles
    $rootScope.table.option.role = metadata.roleFirst
    $scope.data.company_id = metadata.company_id
    $scope.data.roleLength = Object.keys($scope.data.roles).length

    $scope.changeRole = function (roleName) {
      Applications.fetch(roleName, $scope.data.company_id)
    }
  }])
